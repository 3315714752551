// i18next-extract-mark-ns-start products-ignite-cvsm

import { IGNITE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductIgnitePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							IGNITE provides systems-level modelling of all major vehicle sub-systems.
							The state of the art modelling libraries provide comprehensive models of the engine,
							transmission, driveline, vehicle, electrical, controls, thermal management and exhaust aftertreatment.
						</p>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="Vehicle system modelling" />
						</p>
					)}

					<Trans i18nKey="content_2">
						<h2>Key features</h2>
						<ul>
							<li>
								Modelling libraries created using Modelica language as the ideal choice for plant modelling
								applied in powertrain, aftertreatment and drivability domains
							</li>
							<li>
								Flexible model configuration and component fidelity
							</li>
							<li>
								Drag-n-drop component interaction
							</li>
							<li>
								Embedded split-screen for easy navigation through modelling context documentation while setting up the models
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{IGNITE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductIgnitePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-ignite-cvsm", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/ignite/ignite-hero_1152x654.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/ignite/ignite-vehicle-system-modelling.png" }) {
		...imageStandard
	}
}
`;
